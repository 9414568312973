import React from "react";
import { Link } from "gatsby";

import Layout from "../components/layout";
import Image from "../components/image";
import SEO from "../components/seo";

import Modal from "../components/modal";

const IndexPage = () => (
  <Layout>
    <SEO title="TERMS" keywords={[`gatsby`, `application`, `react`]} />
    <Modal>
      <h2>TERMS &amp; CONDITIONS</h2>
      <p>
        Please review the following basic terms that govern your use of and
        purchase of products from the Jeremy Scott™ webstore. Please note that
        your use of the Jeremy Scott webstore constitutes your agreement to
        follow and be bound by those terms.
      </p>
      <h3>GENERAL</h3>
      <p>
        We may, from time to time, change the terms that govern your use of the
        Jeremy Scott™ webstore following any such change constitutes your
        agreement to follow and be bound by the terms as changed. We may change,
        move or delete portions of, or may add to, our webstore from time to
        time.
      </p>
      <h3>IS YOUR SITE A SECURE SITE?</h3>
      <p>
        Any personal information given to Jeremy Scott™ will be protected. Your
        information will not be disclosed to any third parties. Jeremy Scott
        site server(s) will secure your personal information with the latest
        firewall and ecryptions during your ordering process.
      </p>
      <h3>WHAT CREDIT CARDS DO YOU ACCEPT?</h3>
      <p>
        Jeremy Scott™ accepts the following credit cards: Visa, Mastercard and
        American Express.
      </p>
      <h3>PRODUCT INFORMATION</h3>
      <p>
        Jeremy Scott™ products displayed at the site are available in select
        stores in the United States and select foreign markets while supplies
        last. In some cases, merchandise displayed for sale at the Site may not
        be available in stores. The prices displayed at the site are quoted in
        U.S. Dollars and are valid and effective only in the United States.
      </p>
      <h3>PRODUCT AVAILABILITY</h3>
      <p>
        Although we make every effort to keep sufficient stock of items listed
        on our site, occasionally we do sell out of certain products and sizes.
        We cannot guarantee product availability and products, nonetheless, may
        not be available for immediate delivery. We reserve the right, without
        liability or prior notice, to revise, discontinue, or cease to make
        available any or all products or to cancel any order. If there is any
        revision, discontinuance, or cessation, we may, in our discretion, ship
        products which have substantially similar functionality and
        specifications to the products ordered or cancel your order. If we are
        out of stock on an item you have ordered, we will notify you via e-mail.
        Any changes will be reflected in your order total as well as your
        shipping confirmation.
      </p>
      <h3>HOW DO I CHECK THE STATUS OF MY ORDER?</h3>
      <p>
        You will receive an order confirmation once your order has been
        successfully transmitted. You can always check the status of your order
        by signing into my account and then selecting the option order
        status/history. This screen will let you know if your order has been
        fulfilled, shipped, or if it is being held.
      </p>
      <h3>DELIVERY</h3>
      <p>
        Purchased items will be shipped within 7 business days of the order,
        although most will be within 3 to 4 business days. Please allow up to 2
        weeks for the transit time of your merchandise. If you still have not
        received your purchase after 3 weeks, please notify&nbsp;
        <a href="mailto:info@jeremyscott.com">info@jeremyscott.com</a>.
      </p>
      <p>
        Jeremy Scott™ is not responsible for any lost, stolen or damaged
        shipments.
      </p>
      <h3>CUSTOMS</h3>
      <p>
        Any additional taxes, fees, tariffs, import fees, and surcharges levied
        by destination countries are the responsibility of the customer. Jeremy
        Scott cannot determine in advance the amount, if any, that will be
        charged. Please check with customs of your country to ascertain if any
        additional charges will be applied to your order. If you refuse to pay
        customs charges on an incoming shipment, it will be returned to Jeremy
        Scott' at our expense. We will then deduct that charge, as well as any
        additional customs charges, from the refund of the original purchase
        price.
      </p>
      <h3>
        <strong>PRICES, SHIPPING &amp; HANDLING CHARGES</strong>
      </h3>
      <p>
        All prices include charges for shipping and handling or taxes, if
        delivered to customers within the United States. For customers outside
        the United States, additional charges may apply depending on location.
        Please email&nbsp;
        <a href="mailto:info@jeremyscott.com">info@jeremyscott.com</a>&nbsp;for
        additional information.
      </p>
      <h3>
        <strong>RETURN POLICY</strong>
      </h3>
      <p>
        Exchanges are accepted on merchandise within 5 days of receipt for
        domestic and international orders.&nbsp;Exchanged items must HAVE NOT
        BEEN WORN, ALTERED OR DAMAGED, and with ALL tags attached and original
        packaging. NOTE: Shipping and handling and customs charges are
        non-refundable.
      </p>
      <h3>
        <strong>HOW DOES THE RETURN AND/OR EXCHANGE PROCESS WORK?</strong>
      </h3>
      <p>
        All sales are final. To exchange an item, securely pack and seal the
        return merchandise and include our return form in the return package.
        Please specify on the form what you are exchanging the item for. Send
        back the merchandise to us using an insured service to the address
        below. (We cannot accept responsibility for packages that we do not ship
        ourselves).
      </p>
      <p>Please send your return/exchange to:</p>

      <address>
        Jeremy Scott
        <br />
        6424 Santa Monica Blvd
        <br />
        Los Angeles, CA
        <br /> 90038
      </address>

      <p>
        To receive the return form email:&nbsp;
        <a href="mailto:info@jeremyscott.com">info@jeremyscott.com</a>
      </p>
      <h3>
        <strong>USER CONDITIONS</strong>
      </h3>
      <p>
        All trademarks, service marks, trade names and photography are the
        property of Jeremy Scott. No use of these may be made without the prior
        written consent of{" "}
        <a href="http://www.jeremyscott.com">JeremyScott.com</a>&nbsp;except to
        identify the products or services of Jeremy Scott.
      </p>
      <h3>
        <strong>SITE CONTENTS</strong>
      </h3>
      <p>
        Unless otherwise noted, all materials, including images, illustrations,
        designs, icons, photographs, video clips, and written and other
        materials that appear as part of this Site (collectively, the
        "Contents") are copyrights, trademarks, trade dress and/or other
        intellectual properties owned, controlled or licensed by Jeremy Scott™
        collectively, and its subsidiaries and/or affiliates (Jeremy Scott as a
        whole is protected by copyright and trade dress, all worldwide rights,
        titles and interests in and to which are owned by Jeremy Scott™. You may
        not reproduce (except as noted above), publish, transmit, distribute,
        display, modify, create derivative works from, sell or participate in
        any sale of, or exploit in any way, in whole or in part, any of the
        Contents, the Site, or any related software.
      </p>
      <h3>
        <strong>USER SUBMISSIONS</strong>
      </h3>
      <p>
        You agree that Jeremy Scott™ may use and/or disclose information about
        your demographics and use of the Site in any manner that does not reveal
        your identity. By participating in site sweepstakes, contests,
        promotions, and/or requesting promotional information or product
        updates, you agree that Jeremy Scott™ may use your information for
        marketing and promotional purposes.
      </p>
      <h3>
        <strong>HOW DO I ENABLE COOKIES?</strong>
      </h3>
      <p>
        Please{" "}
        <a
          title="Enable Cookies"
          href="http://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences"
          rel="nofollow"
        >
          click here
        </a>
        &nbsp;for instructions on how to enable Cookies on your computer.
      </p>
      <h3>
        <strong>JEREMY SCOTT COMMUNICATIONS TO YOU</strong>
      </h3>
      <p>
        You agree that Jeremy Scott™ may send electronic mail to you for the
        purpose of advising you of changes or additions to this Site, about any
        of Jeremy Scott™' products or services, or for such other purpose(s)
        as&nbsp;Jeremy Scott™ deems appropriate.
      </p>
      <h3>
        <strong>DISCLAIMER</strong>
      </h3>
      <p>
        THIS SITE AND ALL CONTENTS OF THE SITE ARE PROVIDED ON AN "AS IS" BASIS
        WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING
        WITHOUT LIMITATION WARRANTIES OF TITLE OR IMPLIED WARRANTIES OF
        MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE. YOU ACKNOWLEDGE, BY
        YOUR USE OF THE SITE, THAT YOUR USE OF THE SITE IS AT YOUR SOLE RISK,
        THAT YOU ASSUME FULL RESPONSIBILITY FOR ALL COSTS ASSOCIATED WITH ALL
        NECESSARY SERVICING OR REPAIRS OF ANY EQUIPMENT YOU USE IN CONNECTION
        WITH YOUR USE OF OUR SITE, AND THAT Jeremy Scott™ SHALL NOT BE LIABLE
        FOR ANY DAMAGES OF ANY KIND RELATED TO YOUR USE OF THIS SITE.
      </p>
      <h3>
        <strong>INACCURACY DISCLAIMER</strong>
      </h3>
      <p>
        We do our best to make sure that product on our site is described
        accurately and error-free. From time to time there may be information on
        JeremyScott.com that contains typographical errors, inaccuracies, or
        omissions that may relate to product descriptions, pricing, and
        availability. We reserve the right to correct any errors, inaccuracies
        or omissions and to change or update information at any time without
        prior notice (including after you have submitted your order).
      </p>
      <h3>
        <strong>INDEMNIFICATION</strong>
      </h3>
      <p>
        You agree to defend, indemnify and hold&nbsp;Jeremy Scott™ harmless from
        and against any and all claims, damages, costs and expenses, including
        attorneys' fees, arising from or related to your use of the Site.
      </p>
      <h3>
        <strong>MISCELLANEOUS</strong>
      </h3>
      <p>
        Unless otherwise specified and except to the extent&nbsp;Jeremy Scott.™
        products are offered for sale in the United States through this Site,
        this Site and the Contents thereof are displayed solely for the purpose
        of promoting&nbsp;Jeremy Scott™' products and services available in the
        United States and select foreign markets. This Site is controlled and
        operated by&nbsp;Jeremy Scott™ from its office in Los Angeles,
        California. This Agreement shall be construed in accordance with the
        laws of the State of California, without regard to any conflict of law
        provisions. Any dispute arising under this Agreement shall be resolved
        exclusively by the state and federal courts of the State of California,
        County of Los Angeles.
      </p>
      <h3>
        <strong>TERMINATION</strong>
      </h3>
      <p>
        This Agreement is effective unless and until terminated by&nbsp;Jeremy
        Scott™. Jeremy Scott™ may terminate this Agreement at any time and may
        do so immediately without notice, and accordingly deny you access to the
        Site, if in&nbsp;Jeremy Scott™' sole discretion you fail to comply with
        any term or provision of this Agreement. Upon any termination of this
        Agreement by either you or Jeremy Scott™, you must promptly destroy all
        materials downloaded or otherwise obtained from this Site, as well as
        all copies of such materials, whether made under the terms of this
        Agreement or otherwise.
      </p>
      <h3>
        <strong>
          I STILL HAVE SOME UNANSWERED QUESTIONS, WHO DO I CONTACT?
        </strong>
      </h3>
      <p>
        Please contact us with any further questions / feedback / comments or
        suggestions. E-mail:&nbsp;
        <a href="mailto:info@jeremyscott.com">info@jeremyscott.com</a>
      </p>

      <p>Hours of normal operations are 10-6 PM Pacific Standard Time</p>

      <em id="__mceDel">
        Mail:&nbsp;Jeremy Scott / 6424 Santa Monica Blvd, Los Angeles, CA, 90038
      </em>
    </Modal>
  </Layout>
);

export default IndexPage;
